<i18n>
  {
    "es": {
      "SAVE": "Guardar",
      "ALIAS": "Alias* (Ej: Trabajo, Casa...). Min 3 caracteres",
      "SAVE_ADDRESS": "Guardar dirección",
      "SAVING": "Guardando...",
      "ADD_ADDRESS": "Añadir dirección",
      "EDIT_ADDRESS": "Editar dirección",
      "ADDRESS_EXAMPLE": "Dirección (Ej: Avda. España 25)*",
      "EXTRA_ADDRESS": "Puerta / Piso",
      "CITY": "Ciudad*",
      "STATE": "Provincia*",
      "POSTAL_CODE": "Código Postal*",
      "NAME": "Nombre*",
      "LASTNAME": "Apellidos*",
      "CANCEL": "Cancelar",
      "ADDRESS_ADDED": "Dirección añadida correctamente",
      "ADDRESS_EDITED": "Dirección editada correctamente",
      "ERROR": "Ha ocurrido un error",
      "ALIAS_REQUIRED": "Alias es obligatorio",
      "ALIAS_MINLENGTH": "El alias debe tener al menos 3 caracteres",
      "NAME_REQUIRED": "El nombre es obligatorio",
      "NAME_MINLENGTH": "El nombre debe tener más de 3 caracteres",
      "LASTNAME_REQUIRED": "Los apellidos son obligatorios",
      "LASTNAME_MINLENGTH": "Los apellidos deben tener más de 3 caracteres",
      "ADDRESS_REQUIRED": "La dirección es obligatoria",
      "ADDRESS_MINLENGTH": "La dirección debe tener más de 3 caracteres",
      "CITY_REQUIRED": "La ciudad es obligatoria",
      "CITY_MINLENGTH": "La ciudad debe tener más de 3 caracteres",
      "STATE_REQUIRED": "La provincia es obligatoria",
      "STATE_MINLENGTH": "La provincia debe tener más de 3 caracteres",
      "POSTAL_CODE_REQUIRED": "El código postal es obligatorio",
      "POSTAL_CODE_MINLENGTH": "El código postal debe tener más de 3 caracteres",
      "POSTAL_CODE_INTEGER": "El código postal debe ser un número"
    },
    "en": {
      "SAVE": "Save",
      "ALIAS": "Alias* (e.g., Work, Home...)",
      "SAVE_ADDRESS": "Save address",
      "SAVING": "Saving...",
      "ADD_ADDRESS": "Add address",
      "EDIT_ADDRESS": "Edit address",
      "ADDRESS_EXAMPLE": "Address (e.g., Spain Avenue 25)*",
      "EXTRA_ADDRESS": "Door / Floor",
      "CITY": "City*",
      "STATE": "State*",
      "POSTAL_CODE": "Postal Code*",
      "NAME": "Name*",
      "LASTNAME": "Lastname*",
      "CANCEL": "Cancel",
      "ADDRESS_ADDED": "Address added correctly",
      "ADDRESS_EDITED": "Address edited correctly",
      "ERROR": "An error has occurred",
      "ALIAS_REQUIRED": "Alias is required",
      "ALIAS_MINLENGTH": "Alias must be at least 3 characters long",
      "NAME_REQUIRED": "Name is required",
      "NAME_MINLENGTH": "Name must be more than 3 characters long",
      "LASTNAME_REQUIRED": "Lastname is required",
      "LASTNAME_MINLENGTH": "Lastname must be more than 3 characters long",
      "ADDRESS_REQUIRED": "Address is required",
      "ADDRESS_MINLENGTH": "Address must be more than 3 characters long",
      "CITY_REQUIRED": "City is required",
      "CITY_MINLENGTH": "City must be more than 3 characters long",
      "STATE_REQUIRED": "State is required",
      "STATE_MINLENGTH": "State must be more than 3 characters long",
      "POSTAL_CODE_REQUIRED": "Postal Code is required",
      "POSTAL_CODE_MINLENGTH": "Postal Code must be more than 3 characters long",
      "POSTAL_CODE_INTEGER": "Postal Code must be a number"
    },
    "fr": {
      "SAVE": "Enregistrer",
      "ALIAS": "Alias* (par exemple, Travail, Maison...)",
      "SAVE_ADDRESS": "Enregistrer l'adresse",
      "SAVING": "Enregistrement...",
      "ADD_ADDRESS": "Ajouter une adresse",
      "EDIT_ADDRESS": "Modifier l'adresse",
      "ADDRESS_EXAMPLE": "Adresse (par exemple, Avenue d'Espagne 25)*",
      "EXTRA_ADDRESS": "Porte / Étage",
      "CITY": "Ville*",
      "STATE": "Province*",
      "POSTAL_CODE": "Code Postal*",
      "NAME": "Prénom*",
      "LASTNAME": "Nom de famille*",
      "CANCEL": "Annuler",
      "ADDRESS_ADDED": "Adresse ajoutée correctement",
      "ADDRESS_EDITED": "Adresse modifiée correctement",
      "ERROR": "Une erreur s'est produite",
      "ALIAS_REQUIRED": "L'alias est obligatoire",
      "ALIAS_MINLENGTH": "L'alias doit comporter au moins 3 caractères",
      "NAME_REQUIRED": "Le prénom est obligatoire",
      "NAME_MINLENGTH": "Le prénom doit comporter plus de 3 caractères",
      "LASTNAME_REQUIRED": "Le nom de famille est obligatoire",
      "LASTNAME_MINLENGTH": "Le nom de famille doit comporter plus de 3 caractères",
      "ADDRESS_REQUIRED": "L'adresse est obligatoire",
      "ADDRESS_MINLENGTH": "L'adresse doit comporter plus de 3 caractères",
      "CITY_REQUIRED": "La ville est obligatoire",
      "CITY_MINLENGTH": "La ville doit comporter plus de 3 caractères",
      "STATE_REQUIRED": "La province est obligatoire",
      "STATE_MINLENGTH": "La province doit comporter plus de 3 caractères",
      "POSTAL_CODE_REQUIRED": "Le code postal est obligatoire",
      "POSTAL_CODE_MINLENGTH": "Le code postal doit comporter plus de 3 caractères",
      "POSTAL_CODE_INTEGER": "Le code postal doit être un nombre"
    },
    "de": {
      "SAVE": "Speichern",
      "ALIAS": "Alias* (z.B. Arbeit, Zuhause...)",
      "SAVE_ADDRESS": "Adresse speichern",
      "SAVING": "Speichern...",
      "ADD_ADDRESS": "Adresse hinzufügen",
      "EDIT_ADDRESS": "Adresse bearbeiten",
      "ADDRESS_EXAMPLE": "Adresse (z.B. Spanienstraße 25)*",
      "EXTRA_ADDRESS": "Tür / Etage",
      "CITY": "Stadt*",
      "STATE": "Bundesland*",
      "POSTAL_CODE": "Postleitzahl*",
      "NAME": "Vorname*",
      "LASTNAME": "Nachname*",
      "CANCEL": "Abbrechen",
      "ADDRESS_ADDED": "Adresse erfolgreich hinzugefügt",
      "ADDRESS_EDITED": "Adresse erfolgreich bearbeitet",
      "ERROR": "Ein Fehler ist aufgetreten",
      "ALIAS_REQUIRED": "Alias ist erforderlich",
      "ALIAS_MINLENGTH": "Alias muss mindestens 3 Zeichen lang sein",
      "NAME_REQUIRED": "Name ist erforderlich",
      "NAME_MINLENGTH": "Name muss länger als 3 Zeichen sein",
      "LASTNAME_REQUIRED": "Nachname ist erforderlich",
      "LASTNAME_MINLENGTH": "Nachname muss länger als 3 Zeichen sein",
      "ADDRESS_REQUIRED": "Adresse ist erforderlich",
      "ADDRESS_MINLENGTH": "Adresse muss länger als 3 Zeichen sein",
      "CITY_REQUIRED": "Stadt ist erforderlich",
      "CITY_MINLENGTH": "Stadt muss länger als 3 Zeichen sein",
      "STATE_REQUIRED": "Bundesland ist erforderlich",
      "STATE_MINLENGTH": "Bundesland muss länger als 3 Zeichen sein",
      "POSTAL_CODE_REQUIRED": "Postleitzahl ist erforderlich",
      "POSTAL_CODE_MINLENGTH": "Postleitzahl muss länger als 3 Zeichen sein",
      "POSTAL_CODE_INTEGER": "Postleitzahl muss eine Zahl sein"
    },
    "it": {
      "SAVE": "Salva",
      "ALIAS": "Alias* (es. Lavoro, Casa...)",
      "SAVE_ADDRESS": "Salva indirizzo",
      "SAVING": "Salvataggio...",
      "ADD_ADDRESS": "Aggiungi indirizzo",
      "EDIT_ADDRESS": "Modifica indirizzo",
      "ADDRESS_EXAMPLE": "Indirizzo (es. Via Spagna 25)*",
      "EXTRA_ADDRESS": "Porta / Piano",
      "CITY": "Città*",
      "STATE": "Provincia*",
      "POSTAL_CODE": "Codice Postale*",
      "NAME": "Nome*",
      "LASTNAME": "Cognome*",
      "CANCEL": "Annulla",
      "ADDRESS_ADDED": "Indirizzo aggiunto correttamente",
      "ADDRESS_EDITED": "Indirizzo modificato correttamente",
      "ERROR": "Si è verificato un errore",
      "ALIAS_REQUIRED": "Alias obbligatorio",
      "ALIAS_MINLENGTH": "L'alias deve contenere almeno 3 caratteri",
      "NAME_REQUIRED": "Il nome è obbligatorio",
      "NAME_MINLENGTH": "Il nome deve contenere più di 3 caratteri",
      "LASTNAME_REQUIRED": "Il cognome è obbligatorio",
      "LASTNAME_MINLENGTH": "Il cognome deve contenere più di 3 caratteri",
      "ADDRESS_REQUIRED": "L'indirizzo è obbligatorio",
      "ADDRESS_MINLENGTH": "L'indirizzo deve contenere più di 3 caratteri",
      "CITY_REQUIRED": "La città è obbligatoria",
      "CITY_MINLENGTH": "La città deve contenere più di 3 caratteri",
      "STATE_REQUIRED": "La provincia è obbligatoria",
      "STATE_MINLENGTH": "La provincia deve contenere più di 3 caratteri",
      "POSTAL_CODE_REQUIRED": "Il codice postale è obbligatorio",
      "POSTAL_CODE_MINLENGTH": "Il codice postale deve contenere più di 3 caratteri",
      "POSTAL_CODE_INTEGER": "Il codice postale deve essere un numero"
    },
    "pt": {
      "SAVE": "Salvar",
      "ALIAS": "Alias* (ex: Trabalho, Casa...)",
      "SAVE_ADDRESS": "Salvar endereço",
      "SAVING": "Salvando...",
      "ADD_ADDRESS": "Adicionar endereço",
      "EDIT_ADDRESS": "Editar endereço",
      "ADDRESS_EXAMPLE": "Endereço (ex: Av. Espanha 25)*",
      "EXTRA_ADDRESS": "Porta / Andar",
      "CITY": "Cidade*",
      "STATE": "Estado*",
      "POSTAL_CODE": "Código Postal*",
      "NAME": "Nome*",
      "LASTNAME": "Sobrenome*",
      "CANCEL": "Cancelar",
      "ADDRESS_ADDED": "Endereço adicionado corretamente",
      "ADDRESS_EDITED": "Endereço editado corretamente",
      "ERROR": "Ocorreu um erro",
      "ALIAS_REQUIRED": "Alias é obrigatório",
      "ALIAS_MINLENGTH": "O alias deve ter pelo menos 3 caracteres",
      "NAME_REQUIRED": "O nome é obrigatório",
      "NAME_MINLENGTH": "O nome deve ter mais de 3 caracteres",
      "LASTNAME_REQUIRED": "O sobrenome é obrigatório",
      "LASTNAME_MINLENGTH": "O sobrenome deve ter mais de 3 caracteres",
      "ADDRESS_REQUIRED": "O endereço é obrigatório",
      "ADDRESS_MINLENGTH": "O endereço deve ter mais de 3 caracteres",
      "CITY_REQUIRED": "A cidade é obrigatória",
      "CITY_MINLENGTH": "A cidade deve ter mais de 3 caracteres",
      "STATE_REQUIRED": "O estado é obrigatório",
      "STATE_MINLENGTH": "O estado deve ter mais de 3 caracteres",
      "POSTAL_CODE_REQUIRED": "O código postal é obrigatório",
      "POSTAL_CODE_MINLENGTH": "O código postal deve ter mais de 3 caracteres",
      "POSTAL_CODE_INTEGER": "O código postal deve ser um número"
    }
  }
</i18n>

<template>
  <ModalCourtain :visibility="visibility" @close="cancel">
    <template v-slot:title>
      <div class="flex flex-col items-center justify-center w-full bg-white">
        <h2 class="px-8 text-xl text-others-black text-center">
          {{ props.type == 'EDIT' ? t('EDIT_ADDRESS') : t('ADD_ADDRESS') }}
        </h2>
      </div>
    </template>
    <template v-slot:content>
      <div
        v-show="isLoading"
        class="relative isolate overflow-hidden before:border-t before:border-white/40 before:absolute before:inset-0 before:-translate-x-full before:animate-[shimmer_2s_infinite] before:bg-gradient-to-r before:from-transparent before:via-white/40 before:to-transparent"
      >
        <div class="space-y-4">
          <div class="w-full h-[60px] rounded-lg bg-[#d8d8d8]"></div>
          <div class="w-full h-[60px] rounded-lg bg-[#d8d8d8]"></div>
          <div class="flex justify-center items-center gap-x-6">
            <div class="w-1/2 h-[60px] rounded-lg bg-[#d8d8d8]"></div>
            <div class="w-1/2 h-[60px] rounded-lg bg-[#d8d8d8]"></div>
          </div>
          <div class="flex justify-center items-center gap-x-6">
            <div class="w-1/6 h-[60px] rounded-lg bg-[#d8d8d8]"></div>
            <div class="w-5/6 h-[60px] rounded-lg bg-[#d8d8d8]"></div>
          </div>
          <div class="flex justify-center items-center gap-x-6">
            <div class="w-1/2 h-[60px] rounded-lg bg-[#d8d8d8]"></div>
            <div class="w-1/2 h-[60px] rounded-lg bg-[#d8d8d8]"></div>
          </div>
          <div class="flex justify-center items-center gap-x-6">
            <div class="w-1/2 h-[60px] rounded-lg bg-[#d8d8d8]"></div>
            <div class="w-1/2 h-[60px] rounded-lg bg-[#d8d8d8]"></div>
          </div>

          <div class="mt-10 w-full h-14 rounded-lg bg-[#d8d8d8]"></div>
        </div>
      </div>

      <div v-show="!isLoading">
        <div class="mt-2 w-full">
          <form @submit.prevent="handleSubmit">
            <div class="space-y-4">
              <!-- TODO country fixed but svg with arrow as select-->
              <LabelAnimationInput
                v-model="alias"
                required
                id="alias"
                type="text"
                name="alias"
                autocomplete="alias"
                :placeholder="t('ALIAS')"
                :valid="!v$.alias.$invalid || alias === ''"
                @change="v$.alias.$touch()"
              />
              <div class="md:w-full relative h-14">
                <label
                  class="absolute -top-1.5 left-2.5 cursor-text bg-white px-1 text-xxs text-neutral-grey-2"
                  >{{ t('ADDRESS_EXAMPLE') }}</label
                >
                <input
                  ref="addressRef"
                  @focus="adjustScroll"
                  @input="(e) => writingAddress(e)"
                  required
                  type="text"
                  id="address"
                  name="address"
                  :value="address"
                  autocomplete="off"
                  :placeholder="t('ADDRESS_EXAMPLE')"
                  :class="{
                    'border-red-500': v$.address.$invalid && address != '',
                  }"
                  @change="v$.address.$touch()"
                  class="h-full w-full rounded border px-2 text-sm text-neutral-black-2 placeholder-transparent focus:outline-none"
                />
              </div>

              <div class="space-y-4 md:flex md:gap-x-8 md:space-y-0">
                <div class="flex justify-center items-center gap-x-4">
                  <LabelAnimationInput
                    v-model="extraAddress"
                    ref="extraRef"
                    id="addressNumber"
                    type="text"
                    name="addressNumber"
                    autocomplete="addressNumber"
                    :placeholder="t('EXTRA_ADDRESS')"
                  />
                  <LabelAnimationInput
                    v-model="city"
                    required
                    id="city"
                    type="text"
                    name="city"
                    autocomplete="city"
                    :placeholder="t('CITY')"
                    :valid="!v$.city.$invalid || city == ''"
                    @change="v$.city.$touch()"
                  />
                </div>
              </div>
              <div class="flex justify-center items-center gap-x-4">
                <LabelAnimationInput
                  v-model="state"
                  required
                  id="state"
                  type="text"
                  name="state"
                  autocomplete="state"
                  :placeholder="t('STATE')"
                  :valid="!v$.state.$invalid || state == ''"
                  @change="v$.state.$touch()"
                />
                <LabelAnimationInput
                  v-model="postcode"
                  required
                  id="postcode"
                  type="text"
                  name="postcode"
                  autocomplete="postcode"
                  :placeholder="t('POSTAL_CODE')"
                  :valid="!v$.postcode.$invalid || postcode == ''"
                  @change="v$.postcode.$touch()"
                />
              </div>
              <div class="flex justify-center items-center gap-x-4">
                <LabelAnimationInput
                  v-model="firstname"
                  required
                  id="firstname-address"
                  type="text"
                  name="firstname-address"
                  autocomplete="firstname"
                  :placeholder="t('NAME')"
                  :valid="!v$.firstname.$invalid || firstname == ''"
                  @change="v$.firstname.$touch()"
                />
                <LabelAnimationInput
                  v-model="lastname"
                  required
                  id="lastname-address"
                  type="text"
                  name="lastname-address"
                  autocomplete="lastname"
                  :placeholder="t('LASTNAME')"
                  :valid="!v$.lastname.$invalid || lastname == ''"
                  @change="v$.lastname.$touch()"
                />
              </div>
              <PhoneInput
                v-model="telephone"
                :id="'phone-address'"
                :name="'phone-address'"
                v-if="!isLoading"
              />
            </div>
          </form>
        </div>
      </div>
    </template>
    <template v-slot:footer
      ><div
        v-if="!isLoading"
        class="flex flex-col space-y-5 items-center justify-between"
      >
        <button
          :disabled="!enabledButton"
          :class="{ 'opacity-50': !enabledButton }"
          @click="handleSubmit"
          class="uppercase w-full bg-others-black bg-opacity-90 text-white animation-get-light rounded h-14 font-bold text-sm transition-all duration-200 flex items-center justify-center"
        >
          <img
            v-if="saving"
            src="@/assets/svg/icons/icons-loading.svg"
            class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
          />

          <span v-if="saving">{{ t('SAVING') }}</span>
          <span v-else>{{ t('SAVE_ADDRESS') }}</span>
        </button>
      </div></template
    >
  </ModalCourtain>
</template>

<script setup>
import { useVuelidate } from '@vuelidate/core'
import { required, minLength, helpers, integer } from '@vuelidate/validators'
import { Loader } from '@googlemaps/js-api-loader'

const props = defineProps({
  visibility: Boolean,
  address: Object,
  saving: Boolean,
  type: {
    type: String,
    default: 'CREATE',
  },
})

const emit = defineEmits(['close', 'newAddress', 'editAddress', 'formSubmit'])

const { addMessage } = useSnackBar()
const { userData } = useLocalUser()
const { t, locale } = useI18n()

const config = useRuntimeConfig()

const isLoading = ref(true)
const autocompleted = ref(false)

const addressRef = ref()
const extraRef = ref()
const gAddress = ref()
const addressOriginal = ref()
const gAddressJSON = ref()
const providerAddress = ref()

const alias = ref(props.address?.alias ?? '')
const firstname = ref(props.address?.firstname ?? '')
const lastname = ref(props.address?.lastname ?? '')
const address = ref(props.address?.address_street ?? '')
const extraAddress = ref(props.address?.address_complement ?? '')
const postcode = ref(props.address?.postcode ?? '')
const city = ref(props.address?.address_city ?? '')
const state = ref(props.address?.address_state ?? '')
const telephone = ref(
  props.address?.phone
    ? props.address?.phone
    : userData.value?.phone
      ? userData.value?.phone
      : ''
)

const country = ref(props.address?.address_country ?? 'España')

const rules = computed(() => {
  return {
    alias: {
      required: helpers.withMessage(t('ALIAS_REQUIRED'), required),
      minLength: helpers.withMessage(t('ALIAS_MINLENGTH'), minLength(3)),
    },
    firstname: {
      required: helpers.withMessage(t('NAME_REQUIRED'), required),
      minLength: helpers.withMessage(t('NAME_MINLENGTH'), minLength(4)),
    },
    lastname: {
      required: helpers.withMessage(t('LASTNAME_REQUIRED'), required),
      minLength: helpers.withMessage(t('LASTNAME_MINLENGTH'), minLength(4)),
    },
    address: {
      required: helpers.withMessage(t('ADDRESS_REQUIRED'), required),
      minLength: helpers.withMessage(t('ADDRESS_MINLENGTH'), minLength(4)),
    },
    city: {
      required: helpers.withMessage(t('CITY_REQUIRED'), required),
      minLength: helpers.withMessage(t('CITY_MINLENGTH'), minLength(4)),
    },
    state: {
      required: helpers.withMessage(t('STATE_REQUIRED'), required),
      minLength: helpers.withMessage(t('STATE_MINLENGTH'), minLength(4)),
    },
    postcode: {
      required: helpers.withMessage(t('POSTAL_CODE_REQUIRED'), required),
      minLength: helpers.withMessage(t('POSTAL_CODE_MINLENGTH'), minLength(4)),
      integer: helpers.withMessage(t('POSTAL_CODE_INTEGER'), integer),
    },
  }
})

const enabledButton = computed(() => {
  if (props.type == 'EDIT') {
    const addressIsModified =
      alias.value != props.address.alias ||
      firstname.value != props.address.firstname ||
      lastname.value != props.address.lastname ||
      address.value != props.address.address_street ||
      extraAddress.value != props.address.address_complement ||
      postcode.value != props.address.postcode ||
      city.value != props.address.address_city ||
      state.value != props.address.address_state ||
      telephone?.value != props.address.phone
    return (
      addressIsModified &&
      alias.value.length >= 3 &&
      firstname.value.length > 3 &&
      lastname.value.length > 3 &&
      address.value.length > 3 &&
      postcode.value.length > 3 &&
      city.value.length > 3 &&
      state.value.length > 3 &&
      telephone.value?.length > 3
    )
  }
  return (
    alias.value.length >= 3 &&
    firstname.value.length > 3 &&
    lastname.value.length > 3 &&
    address.value.length > 3 &&
    postcode.value.length > 3 &&
    city.value.length > 3 &&
    state.value.length > 3 &&
    telephone.value?.length > 3
  )
})

const v$ = useVuelidate(rules, {
  alias,
  firstname,
  lastname,
  address,
  city,
  state,
  postcode,
})

const loader = new Loader({
  apiKey: config.public.GOOGLE_MAPS_API_KEY,
  version: 'weekly',
})

onMounted(async () => {
  // Promise for a specific library
  loader
    .importLibrary('places')
    .then((places) => {
      autocomplete = new places.Autocomplete(addressRef.value, {
        componentRestrictions: {
          country: [locale.value],
        },
        fields: ['address_components', 'geometry'],
        types: ['address'],
      })
      autocomplete.addListener('place_changed', fillInAddress)
    })
    .catch((e) => {
      // do something
      console.error(e)
    })
})

onUnmounted(() => {
  if (autocomplete) {
    google.maps.event.clearInstanceListeners(autocomplete)
  }
})

watchEffect(() => {
  if (props.type == 'EDIT' && props.address) {
    alias.value = props.address.alias
    firstname.value = props.address.firstname
    lastname.value = props.address.lastname
    address.value = props.address.address_street
    extraAddress.value = props.address.address_complement
    postcode.value = props.address.postcode
    city.value = props.address.address_city
    state.value = props.address.address_state
    telephone.value = props.address.phone
  }

  if (props.type != 'EDIT' && userData.value) {
    telephone.value = userData.value.phone
  }

  if (props.visibility) {
    isLoading.value = false
  } else {
    isLoading.value = true
  }
})

const writingAddress = (event) => {
  address.value = event.target.value
}

let autocomplete
const fillInAddress = () => {
  // Reset values
  city.value = ''
  state.value = ''
  postcode.value = ''
  address.value = ''
  addressOriginal.value = address.value

  // Get the place details from the autocomplete object.
  const place = autocomplete.getPlace()
  let country = ''

  // Get each component of the address from the place details,
  // and then fill-in the corresponding field on the form.
  // place.address_components are google.maps.GeocoderAddressComponent objects
  // which are documented at http://goo.gle/3l5i5Mr

  let number = ''
  let street = ''

  for (const component of place.address_components) {
    const componentType = component.types[0]

    switch (componentType) {
      case 'route': {
        street = component.long_name
        addressOriginal.value = address.value // Field to know if customer modifies address after
        break
      }

      case 'street_number': {
        number = component.long_name
        break
      }

      case 'postal_code': {
        postcode.value = `${component.long_name}${postcode.value}`
        break
      }

      case 'postal_code_suffix': {
        postcode.value = `${postcode.value}-${component.long_name}`
        break
      }
      case 'locality':
        //document.querySelector("#locality").value = component.long_name;
        city.value = component.long_name
        break
      case 'administrative_area_level_2': {
        //document.querySelector("#state").value = component.short_name;
        state.value = component.long_name
        break
      }
      case 'country':
        //document.querySelector("#country").value = component.long_name;
        country = component.short_name
        break
    }
  }

  // After filling the form with address components from the Autocomplete
  // prediction, set cursor focus on the second address line to encourage
  // entry of subpremise information such as apartment, unit, or floor number.
  address.value = `${street}, ${number}`
  gAddress.value = place.address_components
  extraRef.value.input.focus()
  gAddressJSON.value = place
  autocompleted.value = true
  providerAddress.value = 'google'
}

const handleSubmit = async () => {
  v$.value.$validate()

  if (v$.value.$error) {
    const firstError = v$.value.$errors[0]
    addMessage({
      type: 'error',
      result: 'ERROR',
      text: firstError.$message,
    })
    return
  }

  const fullAddress = {
    address_street: address.value,
    address_complement: extraAddress.value,
    address_city: city.value,
    address_state: state.value,
    address_country: country.value,
    postcode: postcode.value,
    country_id: 240,
    place: {
      address_components: gAddressJSON.value?.address_components,
      geometry: gAddressJSON.value?.geometry,
    },
    alias: alias.value,
    firstname: firstname.value,
    lastname: lastname.value,
    phone: telephone.value,
    is_default: props.is_default ?? false,
    provider: providerAddress.value,
  }

  emit('formSubmit', fullAddress)
}

const resetValues = () => {
  alias.value = ''
  firstname.value = ''
  lastname.value = ''
  address.value = ''
  extraAddress.value = ''
  city.value = ''
  state.value = ''
  postcode.value = ''
  telephone.value = ''
}

const cancel = () => {
  resetValues()
  emit('close')
}

watchEffect(() => {
  if (!props.visibility) {
    resetValues()
  }
})
</script>
